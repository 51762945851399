import React from "react";
import "./Footer.css";
import Github from "../../assest/github.png";
import Instagram from "../../assest/instagram.png";
import Linkedin from "../../assest/linkedin.png";
import Logo from "../../assest/logo.png";
const Footer = () => {
  return (
    <div className="footer-container">
      <hr/>
      <div className="footer">
        <div className="socail-logo">
        <img src={Github} alt="" />
        <img src={Instagram} alt="" />
        <img src={Linkedin}alt="" />
        </div>
      <div className="logo-f">
        <img src={Logo} alt="" />
      </div>
      </div>
      <div className="blur blur-footer1"></div>
      <div className="blur blur-footer2"></div>
    </div>
  );
};

export default Footer;
