import React from "react";
import "../Plans/Plans.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assest/whiteTick.png"
const Plans = () => {
  return (
    <div className="plans" id="plans">
      <div className="blur blur-plan1"></div>
      <div className="blur blur-plan2"></div>
      <div className="plans-header">
        <span className="stoke-text">READY TO START</span>
        <span>YOUR JOUNEY</span>
        <span className="stoke-text">NOW WITH US</span>
      </div>
      {/* plans cards */}
      <div className="plans-card">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>${plan.price}</span>
         <div className="features">
            {plan.features.map((feature , i)=>(
                <div className="feature">
                    <img src={whiteTick} alt="" />
                    <span key={i}>{feature}</span>
                </div>
            ))}
         </div>

    {/* buttons */}
    <div><span>See More Benifits</span></div>
    <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
