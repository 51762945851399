import React from "react";
import Header from "../Header/Header";
import "./Home.css";
import home_image from  "../../assest/home_image.png"
import home_image_back from  "../../assest/home_image_back.png"
import Heart from  "../../assest/heart.png"
import calories from  "../../assest/calories.png"
import {motion} from 'framer-motion';
import CountUp from 'react-countup';
const Home = () => {
  const transition = {type:'twin', duration:3}
  return (
    <div className="home" id="home">
      <div className="blur blur-home"></div>
      <div className="left-home">
        <Header />
        {/* the best ad satrats */}
        <div className="the-best-ad">
          <motion.div
             initial ={{ opacity:0, left:'200px'}}
            whileInView = {{opacity:1, left:'7px'}}
             transition={transition}>
          </motion.div>
          <span>The best Fitness clud in the town</span>
        </div>
        {/* the best ad end */}
        {/* heading section starats */}
        <div className="home-heading">
          <div>
            <span className="stoke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Idel body</span>
          </div>
          <div>
            <span>
              In here We Will help you to shape And build your ideal body and
              live up your Life to fullest
            </span>
          </div>
        </div>
        {/* heading section ends */}
   {/* counter section starts */}
        <div className="counter">
          <div>
            <span>
              <CountUp  end={140} start={100} duration={3} prefix="+"/>
            </span>
            <span>Exporrt Coaches</span>
          </div>
          <div>
            <span>
            <CountUp  end={978} start={100} duration={3} prefix="+"/></span>
            <span>Members joined</span>
          </div>
          <div>
            <span><CountUp  end={50} start={0} duration={3} prefix="+"/></span>
            <span>fitness Programs</span>
          </div>
        </div>
    {/* counter  section ends */}
    {/* creating buttons */}
      <div className="home-button">
        <button className="btn">Get started</button>
        <button className="btn">Learn more</button>
      </div>
      </div>
      <div className="right-home">

      <button className="btn">Join now</button>
      <motion.div className="heart-rate" 
       initial ={{right:'-1rem'}}
       whileInView = {{right:'5rem'}}
        transition={{...transition, type:'spring'}}>
         <img src={Heart} alt="" />
         <span>Heart Rate</span>
         <span>116 bpm</span>
      </motion.div>
 {/* img sections */}
 <img src={home_image} alt="" className="home-img" />
 <motion.img src={home_image_back} alt="" className="home-img-back"
initial ={{right:'11rem'}}
whileInView = {{right:'25rem'}}
 transition={transition}/>
 {/* calories */}
 <motion.div className="calories"
 initial ={{opacity:0, right:'70rem'}}
 whileInView = {{ opacity:1, right:'45rem'}}
  transition={transition}>
    <img src={calories} alt="" />
    <div>
        <span>Calories_Burned </span>
           <span>220_kcal</span>
    </div>
    
 </motion.div>

      </div>
    
    </div>
  );
};

export default Home;
