import React from "react";
import "../Testimonials/Testimonials.css";
import { testimonialsData } from "../../data/testimonialsData";
import leftArrow from "../../assest/leftArrow.png";
import rightArrow from "../../assest/rightArrow.png";
import { useState } from "react";
import {motion} from 'framer-motion';
const Testimonials = () => {
  const transition = {type:'twin', duration:2}
  const [selected, setselected] = useState(0);
  const tlength = testimonialsData.length;
  return (
    <div className="testimonals" id="testimonals">
      <div className="left-t">
        <span>Testimonals</span>
        <span className="stoke-text">what They</span>
        <span>say abut Us</span>
        <motion.span
        key={selected}
        initial={{opacity:0 , x:-100}}
        animate={{opacity:1,x:0}}
        exit={{opacity:0,x:100}}
        transition={{...transition, duration:3}}
        >{testimonialsData[selected].review}</motion.span>
        <span>
          <span>{testimonialsData[selected].name}</span>
          {"  "}- {testimonialsData[selected].status}
        </span>
      </div>
      <div className="right-t">
      <motion.div
             initial ={{opacity:0 , x:'-100'}}
            whileInView = {{opacity:1, x:0}}
             transition={transition}>
          </motion.div>
        
      <motion.div
             initial ={{opacity:0 , x:'100'}}
            whileInView = {{opacity:1, x:0}}
             transition={transition}>
          </motion.div>
        
        <motion.img 
        key={selected}
        initial={{opacity:0 , x:100}}
        animate={{opacity:1,x:0}}
        exit={{opacity:0,x:-100}}
        transition={{...transition, duration:3}}
        src={testimonialsData[selected].image} alt="" />
        <div className="arrows">
          <img 
           onClick={()=>{
            selected=== 0
            ?setselected(tlength -1)
            :setselected((prev)=>prev-1)
           }}
          src={leftArrow} alt="" />
          <img
           onClick={()=>{
            selected===tlength-1
            ?setselected(0)
            :setselected((prev)=>prev+1)
           }} src={rightArrow} alt="" />
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
