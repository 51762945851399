import React from "react";
import { useRef } from "react";
import "./Join.css";
import emailjs from '@emailjs/browser';
const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5obl4de', 'template_umhk77i', form.current, 'gA0zCOEFx3eXeR4FX')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div className="join">
      <div className="left-j">
        <hr />
        <div>
          <span className="stoke-text">ready to </span>
          <span>level up</span>
        </div>
        <div>
          <span >your body </span>
          <span className="stoke-text">with us? </span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
            <input type="email" name="user_email" placeholder="Enter your email to Join us" />
            <button className="btn join-btn">Join US</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
