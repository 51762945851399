import React from "react";
import "./Programs.css";
import {programsData} from '../../data/programsData'
import RightArrow from '../../assest/rightArrow.png'
const programs = () => {
  return (
    <div className="programs" id="programs">
      <div className="programs-header">
        <span className="stoke-text">Explore Our</span>
        <span>Programs</span>
        <span className="stoke-text">to shape you</span>
      </div>

      <div className="programs-categories">
        {programsData.map((program)=>(
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now"><span>Join now</span><img src={RightArrow} alt="" /></div>
            </div>
        ))}
      </div>
    </div>
  );
};

export default programs;
