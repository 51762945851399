import React from 'react'
import './Header.css'
import Logo from '../../assest/logo.png'
import { Link } from 'react-scroll';
const Header = () => {
  return (
    <div className="header">
        <img src={Logo} alt="" srcset=""  className='logo'/>
        <ul className='header-menu'>
            <li><Link
             to='home'
             span={true}
             smooth={true}>Home</Link></li>
            <li> <Link
             to='programs'
             span={true}
             smooth={true}>Programs</Link></li>
            <li>  <Link
             to='reason'
             span={true}
             smooth={true}>Why us?</Link></li>
            <li> <Link
             to='plans'
             span={true}
             smooth={true}>plans</Link></li>
            <li> <Link
             to='testimonals'
             span={true}
             smooth={true}>Testimonals</Link></li>
        </ul>
    </div>
  )
}

export default Header
